@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    overflow-x: hidden;
}

html body {
    overflow-x: inherit;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  width: 100%;

  /* background-color: #ffab91; */
  /* background-image: repeating-linear-gradient(-30deg, white 0%, grey 50%, white); */
}

.desktop-background {
    background-color: white;
}

.MuiDataGrid-root .MuiDataGrid-cell{
    white-space: normal !important;
    word-wrap: break-word !important;
}

.MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.3) !important
}

/* appearence of disabled input fields */
.MuiOutlinedInput-root.Mui-disabled {
    background-color:#dddddd
}
.MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgb(0, 0, 0) !important;
}

/* to meke multi select more condense */
.MuiButtonBase-root.MuiMenuItem-root{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.MuiButtonBase-root.MuiCheckbox-root {
    padding-top: 0px !important;
    padding-bottom: 00px !important;
}